import observeDOM from "fq-foundation/Scripts/DomObserver";

String.prototype.interpolate = function (params) {
	const names = Object.keys(params);
	const vals = Object.values(params);
	return new Function(...names, `return \`${this}\`;`)(...vals);
}
var eNurseChatNow = {
	element: ".eNurseChatNow",
	leaveMessagePopup: "#eNurseLeaveMessage",
	qa: {
		base: "https://service.force.com/embeddedservice/5.0/esw.min.js",
		secondary: "https://firstquality--pdcopy.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js",
		gslbBaseURL: "https://service.force.com",
		initSet: [
			'https://firstquality--pdcopy.sandbox.my.salesforce.com',
			// 'https://pdcopy-firstquality.cs59.force.com/liveagent',
			'https://firstquality--pdcopy.sandbox.my.salesforce-sites.com/liveagent',
			"https://service.force.com",
			// '00D2C0000008dQJ',
			'00DDL00000CTw3S',
			'Prevail_eNurse_Chat_Live_Agent',
			{
				baseLiveAgentContentURL: 'https://c.la1-c1cs-ia6.salesforceliveagent.com/content',
                deploymentId: '57233000000KzXz',
                buttonId: '57333000000KzdZ',
                baseLiveAgentURL: 'https://d.la1-c1cs-ia6.salesforceliveagent.com/chat',
                eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I5a00000000QlEAI_1771624e525',
                isOfflineSupportEnabled: false
			}
		],
		settings: {
			displayHelpButton: true,
			language: 'en-US',
			defaultMinimizedText: 'eNanny 24/7',
			disabledMinimizedText: 'Call at 800-584-6721.',
			loadingText: 'Please wait...',
			offlineSupportMinimizedText: '800-584-6721.',
			enabledFeatures: ['LiveAgent'],
			entryFeature: 'LiveAgent'
		}
	},
	prod: {
		base: "https://service.force.com/embeddedservice/5.0/esw.min.js",
		secondary: "https://firstquality.my.salesforce.com/embeddedservice/5.0/esw.min.js",
		gslbBaseURL: "https://service.force.com",
		initSet: [
			'https://firstquality.my.salesforce.com',
			'https://firstquality.secure.force.com/liveagent',
			"https://service.force.com",
			'00D400000007FU7',
			'Prevail_eNurse_Chat_Live_Agent',
			{
				baseLiveAgentContentURL: 'https://c.la3-c2-ia4.salesforceliveagent.com/content',
				deploymentId: '57233000000KzXz',
				buttonId: '57333000000KzdZ',
				baseLiveAgentURL: 'https://d.la3-c2-ia4.salesforceliveagent.com/chat',
				eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I5a00000000QlEAI_1771624e525',
				isOfflineSupportEnabled: false
			}
		],
		settings: {
			displayHelpButton: true,
			language: 'en-US',
			defaultMinimizedText: 'eNanny 24/7',
			disabledMinimizedText: 'Call at 800-584-6721.',
			loadingText: 'Please wait...',
			offlineSupportMinimizedText: '800-584-6721.',
			enabledFeatures: ['LiveAgent'],
			entryFeature: 'LiveAgent',
			storageDomain: window.location.host,
			targetElement: document.body,
		}
	},
	loadScript: function (url, callback) {
		var script = document.createElement("script")
		script.type = "text/javascript";
		if (script.readyState) {
			script.onreadystatechange = function () {
				if (script.readyState == "loaded" ||
					script.readyState == "complete") {
					script.onreadystatechange = null;
					callback("Success");
				}
			};
		} else {
			script.onload = function () {
				callback("Success");
			};
		}
		script.onerror = function () {
			callback("Error")
		}
		script.src = url;
		document.body.appendChild(script);
	},
	initializeChat: function () {
		var self = this;
		self.loadScript(self[self.env].base, function () {
			$(self.element).addClass('active');
			if (!window.embedded_svc) {
				var s = document.createElement('script');
				s.setAttribute('src', self[self.env].secondary);
				s.onload = function () {
					self.initESW(null);
				};
				document.body.appendChild(s);
			} else {
				self.initESW(self[self.env].gslbBaseURL);
			}
		});
	},
	init: function () {
		var self = this;
		$(".agent-available").hide();
		var urlSplit = window.location.hostname.split(".");
		var subdomain = urlSplit[0];
		if (subdomain == "qa" || subdomain == "dev") {
			self.env = "qa";
		} else {
			self.env = "prod";
		}
		self.initializeChat();
		if ($(self.leaveMessagePopup).length > 0) {
			self.loadScript("https://www.google.com/recaptcha/api.js", function () {
				function timestamp() {
					var response = document.getElementById("g-recaptcha-response");
					if (response == null || response.value.trim() == "") {
						var elems = JSON.parse(document.getElementsByName("captcha_settings")[0].value);
						elems["ts"] = JSON.stringify(new Date().getTime());
						document.getElementsByName("captcha_settings")[0].value = JSON.stringify(elems);
					}
				}
				setInterval(timestamp, 500);
			});
		}
	},
	initESW: function (gslbBaseURL) {
		var self = this;
		$(self.leaveMessagePopup).find(".elv-comm-preference").on("change", function () {
			$(".elv-email, .elv-phone").removeAttr("required");
			$(this).closest("form").find(".elv-" + $(this).val().toLowerCase()).attr("required", "required");
		});
		$(self.leaveMessagePopup).find(".elv-phone").on("keypress", function () {
			var e = $(this).next();
			if (!!$(e).is(".field-validation-error")) {
				$(e).remove();
			}
		});
		$(self.leaveMessagePopup).find(".elv-phone").on("blur", function (e) {
			var value = $(this).val();
			value = value.replace(/\s+/gi, "").replace(/\-/gi, "").replace("+1", "");
			if (value.length != 10) {
				if (!$(this).next().is(".field-validation-error")) {
					var span = $('<span class="field-validation-error"/>')
					span.html("Please enter 10 digit mobile number");
					$(this).after(span)
				}
			}
		});

		$(".eNurseChatNow").data("available", false).text("Leave a Message");
		embedded_svc.settings = Object.assign(embedded_svc.settings, self[self.env].settings);
		embedded_svc.addEventHandler('afterDestroy', function () {
			$(".embeddedServiceHelpButton").hide();
		});

		var leaveAMessageFlag = $(self.leaveMessagePopup).length > 0 ? true : false;
		embedded_svc.addEventHandler("onAvailability", function (data) {
			if (data.isAgentAvailable != undefined) {
				$(".eNurseChatNow").data("available", true).text("Chat Now").show();
				$(".agent-available").show();
				$(".agent-unavailable").hide();
			} else {
				$(".eNurseChatNow").data("available", false);
				if (leaveAMessageFlag) {
					$(".eNurseChatNow").text("Leave a Message");
				} else {
					$(".eNurseChatNow").hide();
				}
				$(".agent-available").hide();
				$(".agent-unavailable").show();
			}
		});

		$(".eNurseChatNow").on("click", function () {
			if (!$(this).data("available") && leaveAMessageFlag) {
				$("#enurse").find('.modal-close').click();
				$(self.leaveMessagePopup).addClass("is-active");
				console.log("Leave a message")
			} else {
				$(".embeddedServiceHelpButton .uiButton").trigger("click");
				$(".embeddedServiceHelpButton").show();
				$("#enurse").find('.modal-close').click();
			}
		});
		embedded_svc.addEventHandler('onSettingsCallCompleted', function () {
			var helpButton = $(".embeddedServiceHelpButton .helpButton").get(0);
			$(".embeddedServiceHelpButton").hide();
			if ($(helpButton).find('.message').text() === embedded_svc.settings.defaultMinimizedText) {
				$(".eNurseChatNow").data("available", true).text("Chat Now");
				$(".agent-available").show();
				$(".agent-unavailable").hide();
			} else {
				if (!leaveAMessageFlag) {
					$(".eNurseChatNow").hide();
				} else {
					$(".eNurseChatNow").data("available", false).text("Leave a Message");
				}
				$(".agent-available").hide();
				$(".agent-unavailable").show();
			}
		});
		embedded_svc.init.apply(embedded_svc, self[self.env].initSet);
		observeDOM(document.body, function () {
			if ($(".modalContainer .stateBody .waitingStateContainer").length > 0 && $(".modalContainer .waitingMessage ").text().indexOf("${Agent}") > -1) {
				let template = $(".modalContainer .waitingMessage").text();
				let result = template.interpolate({
					Agent: 'Prevail eNurse'
				});
				$(".modalContainer .waitingMessage ").html(result);
			}
		});
	}
};
eNurseChatNow.init();